.card {
  background-color: var(--white-color);
  padding: 45px 0px;
  border-radius: 5px;
  box-shadow: 7px 5px 8px 2px #00000012;
  position: relative;
}

.tag {
  background-color: var(--dashboard-main-color);
  color: var(--white-color);
  font-size: 18px;
  line-height: 24px;
  font-family: "inter-bold";
  padding: 5px 10px 5px 25px;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  margin-bottom: 10px;
  display: inline-block;
}

.card h3 {
  margin-bottom: 10px;
  color: var(--text-color-black);
}

.card p {
  margin-bottom: 30px;
  font-size: 16px;
  font-family: "inter-regular";
  line-height: 19px;
  color: var(--text-color-black);
}

.card ul {
  padding-left: 0px;
  list-style: none;
}

.card ul li {
  border-bottom: 1px solid var(--table-bg-gray);
  padding: 24px 0px;
  color: var(--text-color-black);
  font-size: 16px;
  line-height: 19px;
  font-family: "inter-regular";
}

.card ul li:last-child {
  border-bottom: none;
}

.btn {
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 10px 36px;
}

.content {
  padding: 0px 20px;
}
