.mainContainer {
  /* background: #d9d9d9; */
  padding: 0px 40px;
  height: calc(100vh - 83px);
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 15px;
}

.headingContainer span {
  font-family: "inter-bold";
  font-size: 40px;
  line-height: 47px;
  color: #000000;
}

.addBtn {
  background: #dc143c;
  border-radius: 5px;
}

.viewDetailBtn {
  padding: 5px 20px;
  border-radius: 10px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 16px;
  font-family: "inter-medium";
}

@media screen and (max-width: 1600px) {
  /* Table */
  .tableBox {
    overflow-x: scroll !important;
  }

  .tableBox > div:nth-child(1),
  .tableBox > div:nth-child(2) {
    width: 1100px;
  }
}
@media (max-width: 991px) {
  .mainContainer {
    padding: 0px 24px;
  }
}
