/*//////////////////////////////////////////////////////////////////
[ FONT ]*/

:root {
  --dashboard-main-half-opacity-color: #242342e0;
  --dashboard-main-color: #242342;
  --dashboard-secondary-color: #5a547e;
  --dashboard-text-color: #52575d;
  --primary-color: #003893;
  --secondary-color: #dc143c;
  --secondary-half-opacity-color: #dc143c7d;
  --text-color-black: #000000;
  /* --text-color-gray: #9c9c9c; */
  --text-dark-gray: #606060;
  --white-color: #ffffff;
  --input-border-color: #aaaaaa;
  /* --button-color: #fff9f1; */
  /* --footerBg-color: #fff3e3; */
  /* --white-smoke-color: #f6f6f6; */
  --placeholder-color: #bababa;
  --yellow-color: #ffc107;
  /* --light-gray: #d6d6d6; */
  /* --pink-color: #f6f6f6; */
  /* --header-orange-color: #fff5e8; */
  /* --very-light-gray: #e2e2e2; */
  --error-color: red;
  --table-bg-gray: #d9d9d9;
}

body {
  /* background: linear-gradient(270deg,
            rgba(254, 243, 226, 1) 0%,
            rgba(255, 255, 255, 1) 100%); */
}

.status-active {
  background-color: #d7f8d7;
  border: 1px solid #08c408;
  border-radius: 10px;
  padding: 6px 10px;
  color: green;
  font-size: 14px;
  font-family: "Open-Sans-medium";
  min-width: 120px;
  display: inline-block;
  text-align: center;
}

.status-pending {
  background-color: #ffa50021;
  border: 1px solid #ffa204fc;
  border-radius: 10px;
  padding: 6px 10px;
  color: #ffa204fc;
  font-size: 14px;
  font-family: "Open-Sans-medium";
  min-width: 100px;
  display: inline-block;
  text-align: center;
}

.status-deactive {
  background-color: #ffe6e6;
  border: 1px solid #f04d4d;
  border-radius: 10px;
  padding: 6px 10px;
  color: red;
  font-size: 14px;
  font-family: "Open-Sans-medium";
  min-width: 120px;
  display: inline-block;
  text-align: center;
}

/* Helvetica */

@font-face {
  font-family: "inter-regular";
  src: url("../fonts/Inter/static/Inter-Regular.ttf");
}

/* Plus Jakarta Display */

@font-face {
  font-family: "inter-bold";
  src: url("../fonts/Inter/static/Inter-Bold.ttf");
}

@font-face {
  font-family: "inter-medium";
  src: url("../fonts/Inter/static/Inter-Medium.ttf");
}

/*//////////////////////////////////////////////////////////////////
  [ RESTYLE TAG ]*/

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

/*---------------------------------------------*/

a {
  font-family: "inter-regular";
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
  outline: none !important;
}

a:hover {
  text-decoration: none;
  color: #a64bf4;
}

body h1 {
  font-size: 60px;
  font-family: "inter-bold";
  line-height: 65px;
}

body h2 {
  font-size: 55px;
  font-family: "inter-bold";
  line-height: 60px;
}

body h3 {
  font-size: 30px;
  font-family: "inter-bold";
}

body h4 {
  font-size: 26px;
  font-family: "inter-bold";
}

body h5 {
  font-size: 24px;
  font-family: "inter-bold";
}

body h6 {
  font-size: 20px;
  font-family: "inter-bold";
}

/*---------------------------------------------*/

h1,
h2,
h3,
h4,
h5,
h6,
p,
button,
a,
svg {
  margin: 0px;
}

body p.large {
  font-size: 24px;
  font-family: "inter-regular";
}

body p.reg,
button {
  font-size: 18px;
  font-family: "inter-regular";
}

body p.p-16 {
  font-size: 16px;
  font-family: "inter-regular";
}

ul,
li {
  margin: 0px;
  list-style-type: none;
}

/*---------------------------------------------*/

input {
  outline: none;
  border: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* / Firefox / */
input[type="number"] {
  -moz-appearance: textfield;
}

textarea {
  outline: none;
  border: none;
}

.main-btn {
  background-color: var(--primary-color);
  color: var(--white-color);
  border-radius: 50px;
  outline: none;
  border: none;
}

@media screen and (max-width: 992px) {
  body h1 {
    font-size: 55px;
    line-height: 60px;
  }

  body h2 {
    font-size: 50px;
    line-height: 55px;
  }

  body h3 {
    font-size: 28px;
  }

  body h4 {
    font-size: 24px;
  }

  body h5 {
    font-size: 22px;
  }

  body h6 {
    font-size: 20px;
  }

  body p.large {
    font-size: 22px;
  }

  body p.reg,
  button {
    font-size: 17px;
  }

  body p.p-16 {
    font-size: 15px;
  }
}

@media screen and (max-width: 768px) {
  body h1 {
    font-size: 50px;
    line-height: 55px;
  }

  body h2 {
    font-size: 45px;
    line-height: 50px;
  }

  body h3 {
    font-size: 26px;
  }

  body h4 {
    font-size: 22px;
  }

  body h5 {
    font-size: 20px;
  }

  body h6 {
    font-size: 19px;
  }

  body p.large {
    font-size: 21px;
  }

  body p.reg,
  button {
    font-size: 16px;
  }
}

@media screen and (max-width: 575px) {
  body h1 {
    font-size: 45px;
    line-height: 50px;
  }

  body h2 {
    font-size: 40px;
    line-height: 45px;
  }

  body h3 {
    font-size: 24px;
  }

  body h4 {
    font-size: 21px;
  }

  body h5 {
    font-size: 19px;
  }

  body h6 {
    font-size: 18px;
  }

  body p.large {
    font-size: 18px;
  }

  body p.reg,
  button {
    font-size: 15px;
  }
}
