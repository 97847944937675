.mainContainer {
  /* background: #d9d9d9; */
  padding: 20px 40px;
  height: calc(100vh - 84.5px);
}

.mainHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 15px;
}

.headingContainer span {
  font-family: "inter-bold";
  font-size: 40px;
  line-height: 47px;
  color: #000000;
}

.addBtn {
  background: #dc143c;
  border-radius: 5px;
}

.viewDetailBtn {
  padding: 5px 20px;
  border-radius: 10px;
  background-color: transparent;
  color: var(--secondary-color);
  font-size: 16px;
  font-family: "inter-medium";
}

.formCol {
  margin-bottom: 20px;
}

.formRow {
  margin: 16px 0;
}

.imgDiv {
  width: 70%;
  height: 100px;
}



.imgDiv img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: 10px;
}
.btnMain{
display: flex;
align-items: center;
gap: 20px;
}
.inputWithBtn{
  display: flex;
    align-items: center;
    gap: 20px;
}
.inputWithBtn button{
  height: 100%;
}
.tableBodyHeight{
  height: 65vh;
}
@media screen and (max-width: 1600px) {

  /* Table */
  .tableBox {
    overflow-x: scroll !important;
  }

  .tableBox>div:nth-child(1),
  .tableBox>div:nth-child(2) {
    width: 1100px;
  }
}

@media (max-width: 991px) {
  .mainContainer {
    padding: 0px 24px;
  }
}