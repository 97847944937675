.inputMain {
  margin: 20px 0;
}
.inputMain input {
  border: 1px solid lightgray;
  width: 100%;
  padding: 10px;
  border-radius: 10px;
}
.btnMain {
  text-align: center;
  margin-top: 20px;
}
.btnMain button{
  width: 100%;
}
