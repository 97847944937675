.container-table100 {
  width: 100%;
  min-height: 100vh;
  background: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
}

.wrap-table100 {
  width: 1170px;
}

/* .table100 {
  background-color: #fff;
} */

table {
  width: 100%;
}

th,
td {
  font-weight: unset;
  padding-right: 10px;
}

.column1 {
  width: 20%;
  padding-left: 40px;
}

.column2 {
  width: 20%;
}

.column3 {
  width: 20%;
}

.column4 {
  width: 20%;
}

.column5 {
  width: 20%;
}

.table100-head th {
  padding-top: 18px;
  padding-bottom: 18px;
}

.table100-body td {
  padding-block: 5px;
}

.table100 {
  position: relative;
  padding-top: 60px;
}

.table100-head {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}

.table100-body {
  max-height: 70vh;
  overflow: auto;
}

.table100.ver1 th {
  font-family: "inter-medium";
  color: var(--white-color);
  font-size: 14px;
  /* line-height: 22px; */
}

.table100.ver1 td {
  font-family: "inter-medium";
  font-size: 14px;
  /* line-height: 19px; */
  /* color: #6c7ae0; */
  color: var(--dashboard-text-color);
  text-transform: capitalize;
}

.table100.ver1 .table100-body tr {
  display: flex;
  /* margin: 10px 0px; */
  align-items: center;
  text-align: center;
  /* border-radius: 10px; */
  padding-block: 5px;
  /* box-shadow: 0px 1px 5px #52575d40; */
  background-color: var(--white-color);
  color: var(--dashboard-text-color);
  /* color: #121212; */
}
.table100.ver1 .table100-body tr:nth-child(even) {
  background-color: #69638b45;
  /* background-color: #f8f6ff; */
}

.table100 .table100-head tr {
  display: flex;
  align-items: center;
  text-align: center;
  /* margin: 0px 30px; */
  background-color: var(--dashboard-main-color);
  /* border-radius: 10px; */
}

.table100.ver1 {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 1px 5px #24234254;
  margin-bottom: 20px;
}

/* width
::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ebebeb;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #cccccc;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #cccccc;
} */
