.page {
  min-height: calc(100vh - 83px);
  background-color: var(--table-bg-gray);
}

.page .row {
  min-height: inherit;
}

.container {
  max-width: 95%;
}

.heading {
  font-size: 40px;
  color: var(--text-color-black);
  padding-top: 20px;
  line-height: 47px;
  font-family: "inter-bold";
  margin-bottom: 0px;
}

.mt20 {
  margin-top: 20px;
}

.mt24 {
  margin-top: 24px;
}

.footerCol {
  margin-top: 40px;
  align-self: flex-end;
}

/*  */
.statsCardsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--white-color);
  box-shadow: 0px 4px 150px 15px rgb(0 0 0 / 8%);
  border-radius: 20px;
  margin-block: 20px;
}

.statsCardsContainer div:nth-child(3) {
  border-right: none;
}

/* Table */
.tableContainer {
  border-radius: 20px;
  background: var(--white-color);
}

.withdrawAmountBtn {
  background-color: var(--secondary-color);
  border-radius: 5px;
  font-size: 18px;
}

.borderBtm {
  border-bottom: 1px solid var(--text-color-black);
}

.headingAndBtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
}

.headingAndBtn h4 {
  font-size: 32px;
  margin-bottom: 0px;
}

.tableDiv {
  padding: 25px 80px;
  background-color: inherit;
}

.tableDiv thead tr {
  margin-bottom: 24px;
}

.tableDiv tbody tr {
  border: 1px solid var(--text-color-black);
}

.tableDiv tbody tr td {
  padding: 16px 32px;
  margin-bottom: 22px;
}

/* For Queries */
@media (max-width: 1440px) {
  .container {
    max-width: 94%;
  }
}

@media screen and (max-width: 1400px) {
  .tableBox {
    overflow-x: scroll !important;
  }

  /* Table */
  .tableBox > div:nth-child(1),
  .tableBox > div:nth-child(2) {
    width: 1000px;
  }
}

@media (max-width: 991px) {
  .container {
    max-width: 97%;
  }
}
@media (max-width: 768px) {
  .statsCardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 150px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    margin-block: 20px;
  }

  .statsCardsContainer > div {
    border: none;
    border-bottom: 1px solid var(--text-color-black);
    height: 130px;
    width: 100%;
    flex-direction: column;
  }

  .statsCardsContainer > div > div {
    justify-content: center;
    align-items: center;
  }

  .statsCardsContainer > div:nth-child(3) {
    border: none;
  }
}
@media (max-width: 578px) {
  .headingAndBtn h4 {
    font-size: 24px;
  }
  .withdrawAmountBtn {
    font-size: 16px;
    padding: 12px;
    margin-top: 12px;
    align-self: flex-end;
  }
  .headingAndBtn {
    padding: 24px;
    flex-direction: column;
    align-items: flex-start;
  }
}
