.forgotContainer {
  background-image: url("../../assets/images/verify-otp-bg.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100vw;
  padding: 0;
}

.rowConatiner {
  width: 100%;
  height: 100%;
}

.leftContainer {
  background-image: url("../../assets/images/forget-password.png");
  align-items: center;
  justify-content: center;
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  display: flex;
  align-self: center;
}

.rightContainer {
  width: 50%;
  display: flex;
  flex-direction: column;
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 0% 5%;
}

.imageContainer {
  width: 140px;
  height: auto;
  align-self: flex-start;
}

.imageContainer>img {
  width: 100%;
  height: auto;
  object-fit: contain;
  object-position: center;
}

.heading {
  font-size: 40px;
  font-weight: 600;
  padding-top: 30px;
  color: var(--red-color);
  align-self: flex-start;
}

.description {
  font-size: 16px;
  color: var(--text-color-black);
  font-weight: 500;
  align-self: flex-start;
  margin-top: 10px;
  margin-bottom: 30px;
}

.buttonContainer {
  width: 100%;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
}

.generalButton {
  border-radius: 10px;
  height: 60px;
  font-size: 20px;
  font-family: "Raleway-Bold";
  padding: 0px;
  width: calc(50% - 10px);
}

.submitButton {
  color: var(--white-color);
  background: var(--red-color);
  border-radius: 50px;
  padding: 0px;
}

.resetButton {
  background: transparent;
  border: 2px solid var(--red-color);
  color: var(--red-color);
  border-radius: 50px;
}

.errorMessage {
  color: #ff2c2c;
  align-self: flex-start;
  margin-top: 5px;
  font-family: "Raleway-Bold";
  font-size: 14px;
}

.input {
  width: 100%;
}

@media (max-width: 1024px) {
  .leftContainer {
    height: 50%;
  }
}

@media (max-width: 768px) {
  .leftContainer {
    display: none;
  }

  .rightContainer {
    width: 100%;
    overflow: hidden;
  }
}

@media (max-width: 425px) {
  .heading {
    font-size: 22px;
  }

  .imageContainer {
    width: 225px;
    height: auto;
  }

  .generalButton {
    height: 54px;
    font-size: 18px;
  }

  .resetButton {
    margin-right: 4%;
  }
}