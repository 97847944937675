.custom-image-upload {
  border: 1px solid var(--input-border-color);
  height: 130px;
  width: 160px;
  margin: 0 20px 20px 0;
  border-radius: 12px;
  overflow: hidden;
  background-color: #fff;
  cursor: pointer;
}

.custom-image-upload img {
  object-fit: cover;
}

.custom-image-upload p {
  font-family: "inter-regular";
  font-size: 12px;
  color: var(--placeholder-color) !important;
}

.addToQueue {
  border: 1px dashed var(--input-border-color);
  background-color: #fff;
}

.addToQueue svg {
  color: var(--input-border-color);
  width: 25px;
  height: 25px;
}

.videoContainer {
  height: 362px !important;
  width: 640px !important;
}

@media (max-width: 578px) {
  .custom-image-upload {
    height: 120px;
    width: 120px;
  }
}