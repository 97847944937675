.mainContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--dashboard-main-color);
}
.innerContainer {
  background: white;
  border-radius: 10px;
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  padding: 80px 100px !important;
}
.loginText h3 {
  color: var(--dashboard-main-color);
  font-size: 53px;
  text-align: center;
}

.loginBtn {
  padding: 0px;
  margin: 20px 0 0 0;
  height: 58px;
  width: 100%;
  font-size: 16px;
}
.inputCol {
  margin: 20px 0;
}
@media (max-width: 768px) {
  .innerContainer {
    max-width: 94%;
    margin: 0 auto;
  }
}
