.btn {
  letter-spacing: -0.36px;
  outline: none;
  border: none;
  background-color: var(--dashboard-main-color);
  color: var(--white-color);
  padding: 12px 30px;
  border-radius: 3px;
  font-size: 13px;
}
