.content {
    background-color: var(--table-bg-gray);
    height: calc(100vh - 89px);
    padding: 40px 0px;
}

.heading {
    color: var(--text-color-black);
    margin-bottom: 30px;
    font-size: 40px;
    font-family: 'inter-bold';

}

.container {
    padding: 0px 40px;
}