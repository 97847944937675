.Container {
  display: flex;
  flex-direction: column;
  position: relative;
}

.labelText {
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "inter-bold";
  font-size: 16px;
  margin-bottom: 10px;
}

.inputBox {
  font-family: "inter-regular";
  font-size: 16px;
  color: var(--text-color-black);
  letter-spacing: 1.4px;
  border-radius: inherit;
  background-color: inherit;
  padding: 12px;
  width: 100%;
}

.noBorder {
  border: none;
}

.inputPassContainer {
  display: flex;
  position: relative;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;

  border-radius: 10px;
}

.inputPassContainer input::placeholder {
  color: var(--placeholder-color);
  font-family: "inter-medium";
  font-size: 16px;
  text-transform: capitalize;
}

.inputPassContainer .passwordIcon {
  position: absolute;
  right: 15px;
  top: 50%;
  bottom: 50%;
  transform: translateY(-50%);
  color: #cacaca;
}

.errorText {
  color: red;
}

.disabled {
}

.leftIconBox {
  position: absolute;
  left: 20px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

.rightIcon {
  position: absolute;
  right: 8px;
  top: 55%;
  bottom: 45%;
  transform: translateY(-50%);
  height: max-content;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/*  Firefox  */
input[type="number"] {
  -moz-appearance: textfield;
}

@media (max-width: 578px) {
  .labelText {
    font-size: 20px;
  }
}
