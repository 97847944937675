.sliderContainer {
  display: flex;
  flex-wrap: wrap;
}
.mainContainer{
  padding: 24px;
  height: calc(100vh - 67px);
  overflow-y: scroll;
}
.formCol{
  margin-bottom: 20px;
}