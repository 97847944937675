.content {
  padding: 20px;
}
.content h3 {
  text-align: center;
  margin-bottom: 20px;
}
.content span p {
  border: 1px solid lightgray;
  padding: 10px;
  border-radius: 7px;
}
.content h6 {
  margin-bottom: 20px;
  margin-top: 10px;
}
