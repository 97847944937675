.mainContainer {
  /* background: #d9d9d9; */
  padding: 0px 40px;
  padding-top: 40px;
  height: calc(100vh - 83px);
}

.blueHeader {
  width: 100%;
  height: 83px;
  background: var(--dashboard-main-color);
}

.headingContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 26px;
}

.headingContainer span {
  font-family: "inter-bold";
  font-size: 40px;
  line-height: 47px;
  color: #000000;
}

.addBtn {
  background: #dc143c;
  border-radius: 5px;
}

.viewDetailBtn {
  padding: 5px 20px;
  border-radius: 10px;
}

.statsCardsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 4px 150px 15px rgb(0 0 0 / 8%);
  border-radius: 20px;
  margin-bottom: 20px;
}

.statsCardsContainer div:nth-child(3) {
  border-right: none;
}
@media (max-width: 1220px) {
  .mainContainer {
    padding: 0px 32px;
  }
}

@media (max-width: 991px) {
  .mainContainer {
    padding: 0px 24px;
  }
}
@media (max-width: 578px) {
  .statsCardsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: 0px 4px 150px 15px rgb(0 0 0 / 8%);
    border-radius: 20px;
    margin-block: 20px;
  }

  .statsCardsContainer > div {
    border: none;
    border-bottom: 1px solid var(--text-color-black);
    height: 130px;
    width: 100%;
    flex-direction: column;
  }

  .statsCardsContainer > div > div {
    justify-content: center;
    align-items: center;
  }

  .statsCardsContainer > div:nth-child(3) {
    border: none;
  }
  .mainContainer {
    /* background: #d9d9d9; */
    padding: 0 24px 24px 24px;
    height: auto;
  }
}
