.textAreaBox {
  position: relative;
}

.textAreaBox label {
  color: var(--dashboard-main-color);
  text-transform: capitalize;
  font-family: "inter-bold";
  font-size: 16px;
  margin-bottom: 10px;
}

.textAreaBox textarea {
  padding: 16px;
  font-size: 16px;
  font-family: "inter-regular";
  color: var(--text-color-black);
  -ms-overflow-style: none;
  scrollbar-width: none;
  width: 100%;
  background-color: var(--white-color);
  box-shadow: 0px 0 5px 2px #0000000d;
  border-radius: 10px;
}

.textAreaBox textarea::placeholder {
  color: var(--placeholder-color);
  font-family: "inter-medium";
  font-size: 16px;
  text-transform: capitalize;
}
