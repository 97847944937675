.navbarContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
  padding-block: 15px;
  max-width: 100%;
  position: relative;
}
.rightContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.headingContainer {
  padding-left: 29px;
}

.headingContainer span {
  font-family: "inter-bold";
  font-size: 35px;
  color: #000000;
  text-transform: capitalize;
}

svg {
  display: inline-block;
  vertical-align: top;
}
.profileImg {
  width: 50px;
  height: 50px;
  box-shadow: 0px 5px 6px var(--dashboard-main-half-opacity-color);
  border-radius: 50%;
  border: 1px solid var(--dashboard-secondary-color);
}
.profileImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.notifyoverlayDiv {
  top: 20px !important;
  left: -10px !important;
  z-index: 2;
}
.notifyIcon {
  margin-right: 20px;
  cursor: pointer;
}

.notificationIconContainer {
}

.signup {
  background-color: transparent;
  color: var(--dashboard-main-color);
  border: 1px solid var(--dashboard-main-color);
  padding: 0;
  height: 43px;
  width: 120px;
}
.login {
  margin-right: 16px;
  padding: 0;
  height: 43px;
  width: 120px;
}

.SettingsoverlayDiv {
  top: 12px !important;
  left: -10px !important;
}
.SettingsPopup {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 0 4px 0 #0000004a;
  padding: 12px 30px;
  width: max-content;
}
.editProfileIcons {
  vertical-align: bottom !important;
  margin-right: 5px;
}
.editprofileDiv {
  margin-bottom: 10px;
  /* text-align: center; */
  color: var(--text-dark-gray);
  cursor: pointer;
}
@media (max-width: 1080px) {
  .notifyIcon {
    margin-right: 16px;
  }
  .profileImg {
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 992px) {
  .navbarContainer {
    justify-content: flex-end;
  }
  .headingContainer {
    all: unset;
    position: absolute;
    width: 100%;
    text-align: center;
  }
}
