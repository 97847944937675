.content {
}

.content h4 {
  text-align: center;
  font-size: 25px;
}

.btnsBox {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.yesBtn {
  background-color: var(--dashboard-main-color);
  padding: 7px 30px;
}

.noBtn {
  background-color: var(--dashboard-text-color);
  padding: 7px 30px;
  margin-left: 20px;
}

/* For Header */
.iconDiv {
  margin-bottom: 6px;
}

.modalHeader {
  padding: 0px;
}

.headingText {
  font-size: 24px;
  color: var(--white-color);
}
.message {
  font-size: 18px;
  font-weight: 500;
  color: var(--dashboard-text-color);
  text-align: left;
}

.aCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mainDiv {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  /* text-align: center; */
  width: 75%;
  margin: auto;
}

.closeButton {
  position: absolute !important;
  top: -50px !important;
  right: 19px !important;
}


.closeButton svg {
  color: #fff;
}

