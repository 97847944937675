.mainContainer {
  width: 480px;
  height: 156px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #000000;
}

.iconContainer {
  width: 80px;
  height: 80px;
  border: 1px solid var(--secondary-color);
  border-radius: 50%;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textContainer {
  display: flex;
  flex-direction: column;
}

.value {
  font-family: "inter-bold";
  font-size: 36px;
  line-height: 44px;
  color: var(--dashboard-main-color);
}

.text {
  font-family: "inter-medium";
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

@media screen and (max-width: 1440px) {
  .iconContainer {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .iconContainer svg {
    font-size: 35px;
  }

  .value {
    font-size: 30px;
    line-height: 35px;
  }

  .text {
    font-size: 20px;
    line-height: 25px;
  }
}

@media screen and (max-width: 1270px) {
  .iconContainer {
    width: 60px;
    height: 60px;
    margin-right: 15px;
  }

  .value {
    font-size: 28px;
    line-height: 33px;
  }

  .text {
    font-size: 19px;
    line-height: 24px;
  }
}

@media screen and (max-width: 1220px) {
  .iconContainer {
    width: 45px;
    height: 45px;
    margin-right: 10px;
  }

  .iconContainer svg {
    width: 23px;
  }

  .value {
    font-size: 23px;
    line-height: 27px;
  }

  .text {
    font-size: 16px;
  }
}

@media screen and (max-width: 992px) {
  .iconContainer {
    width: 50px;
    height: 50px;
    margin-right: 15px;
  }

  .iconContainer svg {
    width: 25px;
  }
  .value {
    font-size: 23px;
    line-height: 33px;
  }

  .text {
    font-size: 20px;
    line-height: 30px;
  }
}

@media screen and (max-width: 768px) {
  .value {
    font-size: 20px;
    line-height: 30px;
  }

  .text {
    font-size: 18px;
    line-height: 28px;
  }
}

@media screen and (max-width: 670px) {
  .value {
    font-size: 18px;
    line-height: 28px;
  }

  .text {
    font-size: 16px;
    line-height: 26px;
  }
}
