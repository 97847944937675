.mainDiv h4 {
  text-align: center;
  font-family: "Bebas-Neue-bold";
  font-size: 40px;
  margin-bottom: 24px;
}
.detailCol,
.descriptionCol {
  margin-bottom: 16px;
}
.detailCol div {
  display: flex;
  gap: 8px;
}
.detailCol div p,
.descriptionCol div p {
  margin-bottom: 0;
  font-size: 16px;
  font-family: "Open-Sans-bold";
}
.detailCol div span,
.descriptionCol div span {
  text-transform: capitalize;
  word-break: break-all;
}
.imgDiv {
  margin-top: 12px;
  width: 227px;
  height: 309px;
  background-color: #eee;
  border-radius: 8px;
}
.imgDiv img {
  width: 100%;
  height: 100%;
}
