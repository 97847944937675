.mainContainer {
  padding: 40px;
}
.mainContainer h2 {
  margin-bottom: 20px;
}
.btnMain {
  display: flex;
  gap: 10px;
}
