.profileEditContainer {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: var(--dashboard-main-color);
}

.profileEditImage_box {
  width: 100%;
  height: 100%;
  border-radius: inherit;
}

.profileEditImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top center;
  border-radius: inherit;
}

.profileEditPen_box {
  width: 36px;
  height: 36px;
  background: var(--secondary-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: -5px;
  bottom: 0;
  opacity: 1;
}

.profileEditPen {
  cursor: pointer;
  font-size: 24px;
}

.file_upload_form3rd[type="file"] {
  display: none;
}

@media (max-width: 992px) {
  .profileEditContainer {
    width: 110px;
    height: 110px;
  }

  .profileEditPen_box {
    width: 30px;
    height: 30px;
    bottom: 5px;
  }

  .profileEditPen {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .profileEditContainer {
    width: 100px;
    height: 100px;
  }

  .profileEditPen_box {
    width: 24px;
    height: 24px;
  }

  .profileEditPen {
    font-size: 16px;
  }
}
