.sliderContainer {
  display: flex;
  flex-wrap: wrap;
}
.mainContainer{
  padding: 24px;
  height: calc(100vh - 67px);
  overflow-y: scroll;
}
.formCol{
  margin-bottom: 20px;
}

.centerDiv {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}
.Input {
  border: 1px solid #ced4da;
  width: 96%;
  height: 50px;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 5px 5px 5px 5px;
}
.descriptionContainer {
  border: 1px solid #ced4da;
  width: 96%;
  height: 150px;
  /* margin-top: 10px; */
  margin-left: 20px;
  border-radius: 5px 5px 5px 5px;
}
.mtop {
  margin: 20px 0px 0px 10px;
}
.signUp_btn {
  /* display: flex; */
  font-size: 18px !important;
  letter-spacing: 0px !important;
  background-color: #f4dc00 !important;
  color: #131011 !important;
  padding: 0 !important;
  border-radius: 0 !important;
  width: 440px;
  height: 51px;
  margin: 20px 0px 20px 0px;
}
.addLink {
  text-align: right;
  margin-right: 20px;
  padding: 5px;
  margin-top: 20px;
  cursor: pointer;
}
.container {
  background-color: #eeeeee;
  padding: 0px 0px 20px 20px;
  margin-bottom: 30px;
  border-radius: 10px;
}
