.page {
  min-height: calc(100vh - 83px);
  background-color: var(--table-bg-gray);
}

.mainHeading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

.accountInfoSection {
  /* margin-block: 20px; */
  /* padding: 70px 0px; */
  height: calc(100vh - 83px);
  overflow-y: auto;
}

.container {
  margin: 70px auto;
  max-width: 95%;
}

.contentRow h2 {
  font-size: 40px;
  line-height: 47px;
  font-family: "inter-bold";
  margin-bottom: 0px;
}

.infoBox {
  background-color: var(--white-color);
  padding: 34px;
  border-radius: 10px;
}

.mt24 {
  margin-top: 24px;
}

.saveBtn {
  background-color: var(--secondary-color);
}

.changePass {
  background-color: var(--secondary-color);
}

.hotelInfo_row {
  margin-top: 50px;
  margin-bottom: 30px;
}


.checkbox_mainCol {
  margin-top: 20px;
}

.checkbox_main {
  display: flex;
  gap: 40px;
}

.description_main {
  margin-top: 50px;
}

.discountPer {
  margin-top: 15px;
}

.photosColMain {
  margin-top: 30px;
}

.photosColMain p {
  font-size: 18px;
  font-family: "inter-medium" !important;
}

.imageComp_main {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  column-gap: 10px;
}

.imageComp_main .imageBox {
  margin: 0px !important;
}

/* For Queries */
@media (max-width: 992px) {
  .container {
    max-width: 90%;
  }

  .contentRow h2 {
    font-size: 35px;
  }

  .changePass {
    padding: 10px 30px;
    font-size: 16px;
  }

  .contentRow label {
    font-size: 16px;
  }

  .contentRow input {
    font-size: 16px;
  }
}

@media screen and (max-width:768px) {
  .contentRow h2 {
    font-size: 30px;
  }

  .changePass,
  .saveBtn {
    padding: 8px 25px;
    font-size: 15px;
  }

  .contentRow label {
    font-size: 15px;
  }

  .contentRow input {
    font-size: 15px;
  }

}


@media (max-width: 578px) {
  .container {
    max-width: 94%;
  }

  .contentRow h2 {
    font-size: 26px;
  }

  .changePass,
  .saveBtn {
    padding: 8px 20px;
    font-size: 14px;
  }

  .mt24 {
    margin-top: 18px;
  }

  .contentRow label {
    font-size: 15px;
  }

  .contentRow input {
    font-size: 15px;
  }
}

@media screen and (max-width:500px) {
  .mainHeading {
    flex-direction: column;
    align-items: unset;
  }

  .mainHeading .changePass {
    align-self: flex-end;
  }

  .imageComp_main .imageBox {
    width: 110px;
    height: 110px;
  }

  .infoBox {
    padding: 20px;
  }
}

@media screen and (max-width:460px) {
  .imageComp_main .imageBox {
    width: calc(50% - 5px);
    height: 110px;
  }

}

@media screen and (max-width:375px) {
  .infoBox {
    padding: 20px 15px;
  }
}